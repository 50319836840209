
import localization from '~/mixins/localization'

export default {
  mixins: [localization],

  props: {
    value: {
      type: [Boolean, Array],
      default: null,
    },

    isTermsCheckbox: {
      type: Boolean,
      default: false,
    },

    spaceBetween: {
      type: Boolean,
      default: false,
    },

    inline: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      required: null,
      default: null,
    },

    label: {
      type: String,
      default: '',
    },

    options: {
      type: Object,
      default: null,
    },

    rules: {
      type: [String, Object],
      default: '',
    },

    validationMode: {
      type: String,
      default: 'lazy',
    },

    instructions: {
      type: String,
      default: null,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerValue: this.value,
    }
  },

  computed: {
    computedOptions() {
      return Object.entries(this.options)
    },
  },

  watch: {
    innerValue(innerValue) {
      if (!innerValue) {
        return this.$emit('input', null)
      }

      this.$emit('input', innerValue)
    },

    value(value) {
      this.innerValue = value
    },
  },
}
