var render = function render(_c,_vm){return _c('div',{staticClass:"py-7 md:py-10",class:[_vm.data.class, _vm.data.staticClass]},[_c('div',{staticClass:"flex items-start"},[_c('span',{staticClass:"shrink-0 flex justify-center items-center mt-1 w-6 h-6 text-sm font-black text-white rounded-full",class:[
        { 'bg-red': _vm.props.type === 'error' },
        {
          'bg-[#B9D36C]':
            _vm.props.type === 'info' || _vm.props.type === 'success' || !_vm.props.type,
        },
      ]},[(_vm.props.type !== 'success')?[_vm._v("i")]:_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"14.355","height":"12.612","viewBox":"0 0 14.355 12.612"}},[_c('path',{attrs:{"id":"Path_357","data-name":"Path 357","d":"M22490.207-11649.718l3.8,2.676,4.977-7.041","transform":"translate(-22487.42 11656.87)","fill":"none","stroke":"#fff","stroke-linecap":"round","stroke-width":"4"}})])],2),_vm._v(" "),(_vm.props.title)?_c('span',{staticClass:"ml-2 text-lg font-black"},[_vm._v("\n      "+_vm._s(_vm.props.title)+"\n    ")]):_vm._e()]),_vm._v(" "),(_vm.props.text)?_c('div',{staticClass:"prose-a:underline-offset-5 md:typo-300 mt-3 text-base prose-a:underline prose",domProps:{"innerHTML":_vm._s(_vm.props.text)}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }