import { render, staticRenderFns } from "./NewsletterSignUp.vue?vue&type=template&id=1303c7cc"
import script from "./NewsletterSignUp.vue?vue&type=script&lang=js"
export * from "./NewsletterSignUp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Subtitle: require('/vercel/path0/components/general/Subtitle.vue').default,InputField: require('/vercel/path0/components/general/form-elements/InputField.vue').default,Checkboxes: require('/vercel/path0/components/general/form-elements/Checkboxes.vue').default,FormMessage: require('/vercel/path0/components/general/FormMessage.vue').default,TransitionExpand: require('/vercel/path0/components/general/TransitionExpand.vue').default,AppButton: require('/vercel/path0/components/general/AppButton.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default})
