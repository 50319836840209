
import { mapState } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      state: null,
      form: {
        email: '',
        first_name: '',
        last_name: '',
        interests: [],
      },
    }
  },

  computed: {
    ...mapState('globals', ['newsletterTexts']),

    /**
     * Map the interest IDs to their labels
     */
    interests() {
      const map = new Map()

      // If the user has overwritten the groups, use those
      if (this.data.overwrite_groups?.length > 0) {
        this.data.overwrite_groups?.forEach(({ value, label }) => {
          const translatedLabel = this.$te(value) ? this.$t(value) : label
          map.set(value, translatedLabel)
        })
      } else {
        // otherwise fallback to the default groups
        this.newsletterTexts?.groups?.forEach(({ value, label }) => {
          const translatedLabel = this.$te(value) ? this.$t(value) : label
          map.set(value, translatedLabel)
        })
      }

      return Object.fromEntries(map)
    },
  },

  methods: {
    async submit() {
      this.state = 'loading'
      try {
        await this.$axios.$post(
          'api/newsletter/subscribe',
          { ...this.form, availableInterests: Object.keys(this.interests) },
          {
            headers: {
              'Accept-Language': this.$i18n.locale,
            },
          }
        )
        this.state = 'success'
      } catch (e) {
        this.state = 'error'
        console.log(e)
      }
    },
  },
}
