export default {
  methods: {
    localizedLabel(text) {
      let label = text || this.label

      if (!label) return ''

      label = this.$removeHtmlTags(label)
      const splitted = label.split('|')

      // If there is a translation
      if (splitted.length > 1) {
        // pick the the correct one based on the current locale.
        // first item is DE, second is FR, third is IT
        let translation =
          splitted[
            this.$i18n.locale === 'de' ? 0 : this.$i18n.locale === 'fr' ? 1 : 2
          ]?.trim()

        // check if array has two items and the current locale is IT
        if (splitted.length === 2 && this.$i18n.locale === 'it') {
          translation = splitted[0]
        }

        return translation
      }

      // If there is no translation
      return splitted[0]
    },
  },
}
