
import localization from '~/mixins/localization'

export default {
  mixins: [localization],

  props: {
    handle: {
      type: String,
      required: true,
    },

    value: {
      type: null,
      required: true,
    },

    rules: {
      type: String,
      default: '',
    },

    characterLimit: {
      type: [String, Number],
      default: null,
    },

    tag: {
      type: String,
      default: 'input',
    },

    name: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    placeholder: {
      type: String,
      default: '',
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    hideTextareaLabel: {
      type: Boolean,
      default: false,
    },

    instructions: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      innerValue: this.value,
    }
  },

  computed: {
    slug() {
      return this.label
        ?.toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')
    },

    computedRules() {
      if (this.characterLimit) {
        return this.rules + `|max:${this.characterLimit.toString()}`
      }

      return this.rules
    },
  },

  watch: {
    innerValue(innerValue) {
      this.$emit('input', innerValue)
    },

    value(value) {
      this.innerValue = value
    },
  },

  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    }
  },
}
